import React, { useState, useEffect } from "react";
import addIcon 	from "../../../../assets/images/add.svg";
import closeIcon 	from "../../../../assets/images/close.svg";
import startblueIcon 	from "../../../../assets/images/start-blue.svg";
import questionIcon 	from "../../../../assets/images/question.svg";

function Index(props) {

    const [argumentData, setArgumentData] = useState([]);

    const jumTo =(ind)=>{
    	props.handleJumpToNode(ind);
    };

    useEffect(() => {
    	setArgumentData(props.data);
    }, [props.data]);

    return (
        <>
		  	<div className="argumentmap_progressbar">    
		        <div className="p-relative">
		        	{argumentData.length >0 && argumentData.map((item,index)=>
		        		<React.Fragment  key={index} >
				          	<div className="step" onClick={()=>jumTo(0)}>
					            <div className="start-points p-relative">
						            <div className="progressbar">
						                <div className="progress" style={{width: "200px"}} onClick={()=>jumTo(0)}>{item.name}
						                </div>
						                <div className="action_icon"><a href="#"><img src={startblueIcon} className="icon" alt="start" /></a></div>
						            </div>
					            </div>
				            </div>
				           	{item.children.length && item.children.map((subItem,subIndex)=>
					          	<div className="step"  key={subIndex} onClick={()=>jumTo(subIndex)}>
					            	{((subItem.visible == false && subItem.isObjection == true && subItem.children.filter(su=>su.type==1).length >0 ) || (subItem.visible == true)) &&
						            	<div className={` p-relative ${subItem.highlightObjection>0 && subItem.isObjection == true ? subItem.visible == true && subItem.isObjection == true ? "objection-indecator-black" : "objection-black" :"" }  ${subItem.objDisabled==true && subItem.isObjection == true ? subItem.visible == true && subItem.isObjection == true ? "objection-indecator-disable" : "objection-disable" :"" }   ${subItem.highlightClaim>0 && subItem.visible == true && "reason-black request-black"}  ${subItem.disabled==true && subItem.visible==true && "reason-disable request-disable"} ${subItem.objDisabled==true &&  subItem.disabled==true && "section-disable"} ${ subItem.visible == false   ? "objection"  : subItem.isClaim==0 ? "demand" :"reasons" } ${subItem.children.filter(su=>su.type==1).length >0 && subItem.isObjection== true && subItem.visible == true ? "both" : ""}`}>
						              		{subItem.visible == true && subItem.isObjection == true && subItem.children.filter(su=>su.type==1).length >0 &&
						              			<div className="objection_indecator"></div>
						              		}
						              			<div className="progressbar">
						              			    {subItem.visible == true ?
							                			<div className="progress" style={{width: "150px"}}>
							                  				{subItem.isClaim==1 ? 
							                  					"iddia(1)"
							                  				:
							                  					"Talep(1)"
							                  				}
							                  				{subItem.children.filter(su=>su.type==1).length >0 && subItem.isObjection==true  &&
							                  					<div className="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0" data-art={(subItem.children.filter(su=>su.type==1).length*100)} data-di={subItem.children.filter(su=>su.type==1).length+1} aria-valuemax="100" style={{width:(subItem.children.filter(su=>su.type==1).length*100)/(subItem.children.filter(su=>su.type==1).length+1)+"%"}}>itiraz({subItem.children.filter(su=>su.type==1).length})
							                  			    	</div>
							                  			    }
							                			</div>
							                			:
							                			<div className="progress" style={{width: "150px"}}>
							                				itiraz({subItem.children.filter(su=>su.type==1).length})
							                			</div>
							                		}
						                		<div className="action_icon">
						                		    {subItem.isClaim==1 && subItem.visible == true &&
						                  				<a href="#" className={`${subItem.disabled==true && "disabled" }`} ><img src={addIcon} className="icon" alt="question" /></a>
						                  			}
						                  			{subItem.isClaim==0 && subItem.visible == true  &&
						                  				<a href="#" className={`${subItem.disabled==true && "disabled" }`}><img src={questionIcon} className="icon" alt="question" /></a>
						                  			}
						                  			{subItem.children.filter(su=>su.type==1).length >0 && subItem.isObjection== true && 
						                  				<a href="#" className={`${subItem.objDisabled==true  && "disabled" }`}><img src={closeIcon} className="icon" alt="question" /></a>
						                  			}
						                  			
						                		</div>
						              		</div>
						            	</div>
						            }
					          	</div>
					        )}

				        </React.Fragment>
		          	)}

		          	{/*<div className="step">
		            	<div className="both p-relative">
		              		<div className="counter_argument_indecator"></div>
		              			<div className="progressbar">
		                			<div className="progress" style={{width: "150px"}}>
		                  				 1 Ev
		                  				<div className="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"60%"}}>1 Ob
		                  			    </div>
		                			</div>
		                		<div className="action_icon">
		                  			<a href="#"><img src={addIcon} className="icon" alt="question" /></a>
		                  			<a href="#"><img src={closeIcon} className="icon" alt="question" /></a>
		                		</div>
		              		</div>
		            	</div>
		          	</div>

		          	<div className="step">
		            	<div className="both p-relative">
		              		<div className="counter_argument_indecator"></div>
		              			<div className="progressbar">
		                			<div className="progress" style={{width: "150px"}}>
		                  				 1 Ev
		                  				<div className="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"60%"}}>1 Ob
		                  			    </div>
		                			</div>
		                		<div className="action_icon">
		                  			<a href="#"><img src={addIcon} className="icon" alt="question" /></a>
		                  			<a href="#"><img src={closeIcon} className="icon" alt="question" /></a>
		                		</div>
		              		</div>
		            	</div>
		          	</div>*/}

		        </div>
		    </div>
        </>
    );
}

export default Index;
